<template>
  <el-row class="table">
    <span class="title">片区管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入片区名称"
        v-model.trim="inputVal"
        clearable
        class="tb-sm-input"
        @keyup.enter.native="search"
      >
      </el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button">新增</el-button>
      <el-button type="primary" size="medium" @click="syncDing" class="tb-button">同步钉钉</el-button>
      <el-popover class="pointer inline-block" placement="right-start" trigger="hover" width="400">
        <el-row class="condition-con">
          <p>1、将指定为教学片区的钉钉部门及子部门同步为tms的教区</p>
          <p>2、将相应的钉钉部门成员同步为教区成员</p>
          <p>3、用户未绑定钉钉的，不会出现在教区成员中。所以，需要先绑定好钉钉用户和tms用户</p>
        </el-row>
        <span slot="reference">
          <span class="el-icon-question"></span>
        </span>
      </el-popover>
    </el-row>

    <el-table :data="dataList" border fit highlight-current-row class="tb-list mt-15">
      <el-table-column align="center" label="片区名称" prop="name"></el-table-column>
      <el-table-column align="center" label="来源" prop="from" width="200"></el-table-column>
      <el-table-column align="center" label="操作" width="750">
        <template slot-scope="scope">
          <el-popover
            placement="top-start"
            width="150"
            trigger="click"
            class="ml-10 mr-10"
          >
            <template v-if="campusList.length === 0">
              暂无数据
            </template>
            <template v-else>
              <ul v-for="item of campusList" :key="item.id">
                <li>{{item.name}}</li>
              </ul>
            </template>
            <el-button slot="reference" type="text" @click="serveCampus(scope.row)">服务校区</el-button>
          </el-popover>
          <!-- :ref="scope.row.id" -->
          <!-- <el-popover
            placement="top"
            width="150"
            trigger="click"
            :ref="scope.row.id"
            class="ml-10 mr-10"
          >
            <ul v-for="item of taList" :key="item.id">
              <li>
                <span>{{item.name}}</span>
                <el-button type="text" @click="confirmBind(scope.row, item)" class="ml-10">绑定</el-button>
              </li>
            </ul>
            <el-button slot="reference" type="text" @click="bindDing(scope.row)">绑定钉钉片区</el-button>
          </el-popover> -->
          <!-- <el-button type="text" @click="bindDing(scope.row)">绑定钉钉片区</el-button> -->
          <el-button type="text" @click="memberMan(scope.row)" v-has="'area:memberMan'">成员管理</el-button>
          <el-button type="text" @click="edit(scope.row)" v-has="'area:editArea'">编 辑</el-button>
          <el-button type="text" @click="del(scope.row)" v-has="'area:delArea'">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 添加片区 -->
    <add-area :show="areaDialog" @close="areaDialog=false;AreaData=null" :AreaData="AreaData" @addArea="addArea" @editArea="editArea"></add-area>
    <!-- 片区成员 -->
    <area-manage :show="manageDialog" @close="manageDialog=false" :taid="taid"></area-manage>
  </el-row>
</template>

<script>
import { getAreaList, addTeacharea, editTeacharea, delTeacharea, getTeachareaInfo } from '@/api/system/area'
import { getChildTa } from '@/api/ding'
import { getchildDp } from '@/api/system/staff'
import addArea from '@/components/system/addArea'
import areaManage from '@/components/system/areaManage'

export default {
  components: {
    addArea,
    areaManage
  },
  data () {
    return {
      dataList: [],
      inputVal: '',
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      areaDialog: false,
      AreaData: null,
      manageDialog: false,
      campusList: [],
      taid: '', // 片区id
      taList: []
    }
  },
  created () {
    this.getAreaList()
  },
  methods: {
    async getAreaList () {
      const res = await getAreaList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        name: this.inputVal
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    search () {
      this.pagination.currentPage = 1
      this.getAreaList()
    },

    reset () {
      this.inputVal = ''
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getAreaList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getAreaList()
    },

    add () {
      this.areaDialog = true
    },

    edit (row) {
      this.AreaData = row
      this.areaDialog = true
    },

    del (row) {
      this.$confirm('是否删除当前片区', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTeacharea({
            id: row.id
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('删除成功')
              setTimeout(() => {
                this.getAreaList()
              }, 0)
            }
          })
        })
        .catch(() => {
          return false
        })
    },

    syncDing () {
      getChildTa().then(res => {
        if (res.state === 'success') {
          window.$msg('同步成功')
          this.getAreaList()
        }
      })
    },

    serveCampus (row) {
      // this.$refs[row.id].doClose()
      getTeachareaInfo({
        id: row.id
      }).then(res => {
        if (res.state === 'success') {
          this.campusList = res.body.campusList
        }
      })
    },

    bindDing (row) {
      getchildDp({
        dpid: 'teacharea'
      }).then(res => {
        if (res.state === 'success') {
          this.taList = res.body
        }
      })
    },

    confirmBind (row) {

    },

    memberMan (row) {
      this.taid = row.id
      this.manageDialog = true
    },

    addArea (data) {
      addTeacharea({
        name: data.name,
        campusIdSet: data.campusIdSet,
        status: '已启用'
      }).then (res => {
        if (res.state === 'success') {
          window.$msg('新增成功')
          this.getAreaList()
        }
      })
    },

    editArea (data) {
      editTeacharea({
        id: data.id,
        name: data.name,
        campusIdSet: data.campusIdSet,
        status: '已启用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('编辑成功')
          this.getAreaList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scope>
@import "@/style/table.scss";
.condition-con {
  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
