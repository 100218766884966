var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("片区管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "输入片区名称", clearable: "" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.inputVal,
              callback: function ($$v) {
                _vm.inputVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "inputVal",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: { click: _vm.add },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.syncDing },
            },
            [_vm._v("同步钉钉")]
          ),
          _c(
            "el-popover",
            {
              staticClass: "pointer inline-block",
              attrs: {
                placement: "right-start",
                trigger: "hover",
                width: "400",
              },
            },
            [
              _c("el-row", { staticClass: "condition-con" }, [
                _c("p", [
                  _vm._v(
                    "1、将指定为教学片区的钉钉部门及子部门同步为tms的教区"
                  ),
                ]),
                _c("p", [_vm._v("2、将相应的钉钉部门成员同步为教区成员")]),
                _c("p", [
                  _vm._v(
                    "3、用户未绑定钉钉的，不会出现在教区成员中。所以，需要先绑定好钉钉用户和tms用户"
                  ),
                ]),
              ]),
              _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c("span", { staticClass: "el-icon-question" }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list mt-15",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "片区名称", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "来源",
              prop: "from",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "750" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        staticClass: "ml-10 mr-10",
                        attrs: {
                          placement: "top-start",
                          width: "150",
                          trigger: "click",
                        },
                      },
                      [
                        _vm.campusList.length === 0
                          ? [_vm._v(" 暂无数据 ")]
                          : _vm._l(_vm.campusList, function (item) {
                              return _c("ul", { key: item.id }, [
                                _c("li", [_vm._v(_vm._s(item.name))]),
                              ])
                            }),
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.serveCampus(scope.row)
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v("服务校区")]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "area:memberMan",
                            expression: "'area:memberMan'",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.memberMan(scope.row)
                          },
                        },
                      },
                      [_vm._v("成员管理")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "area:editArea",
                            expression: "'area:editArea'",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编 辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "area:delArea",
                            expression: "'area:delArea'",
                          },
                        ],
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row)
                          },
                        },
                      },
                      [_vm._v("删 除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-area", {
        attrs: { show: _vm.areaDialog, AreaData: _vm.AreaData },
        on: {
          close: function ($event) {
            _vm.areaDialog = false
            _vm.AreaData = null
          },
          addArea: _vm.addArea,
          editArea: _vm.editArea,
        },
      }),
      _c("area-manage", {
        attrs: { show: _vm.manageDialog, taid: _vm.taid },
        on: {
          close: function ($event) {
            _vm.manageDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }