<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="AreaDialogTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="片区名称：" prop="name">
        <el-input v-model="formData.name" placeholder="请输入片区名称"></el-input>
      </el-form-item>
      <el-form-item label="服务校区：" prop="campusIdSet">
        <el-select v-model="formData.campusIdSet" multiple placeholder="请选择">
          <el-option
            v-for="item in campusList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getCampusAll } from '@/api/senate/campus'
import { getTeachareaInfo } from '@/api/system/area'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    AreaData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      AreaDialogTitle: '',
      formData: {
        name: '',
        campusIdSet: []
      },
      rules: {
        name: [{ required: true, message: '请输入片区名称', trigger: 'blur' }],
        campusIdSet: [{ required: true, message: '请选择服务校区', trigger: 'blur' }],
      },
      campusList: []
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        name: '',
        campusIdSet: []
      }
      this.getCampusAll()
      if (this.AreaData === null) {
        this.AreaDialogTitle = '新增片区'
      } else {
        this.AreaDialogTitle = '编辑片区'
        this.formData = Object.assign({}, this.AreaData)
        this.getTeachareaInfo()
      }
    },

    getTeachareaInfo () {
      getTeachareaInfo({
        id: this.AreaData.id
      }).then(res => {
        if (res.state === 'success') {
          console.log('res', res)
          let campusIdSet = []
          res.body.campusList.forEach((ele, idx) => {
            campusIdSet.push(ele.id)
          })
          this.formData.campusIdSet = campusIdSet
        }
      })
    },

    async getCampusAll () {
      const res = await getCampusAll({})
      this.campusList = res.body
    },

    // 关闭
    close () {
      this.$emit('close')
    },
    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.AreaData === null) {
            this.$emit('addArea', this.formData)
          } else {
            let editData = {id: this.AreaData.id, ...this.formData}
            this.$emit('editArea', editData)
          }
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/dialog.scss';
</style>
