var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "70%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("片区成员管理")])]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 50 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-row", [_vm._v("员工列表")]),
              _c(
                "el-row",
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: {
                        width: "380px",
                        margin: "10px 10px 10px 0",
                      },
                      attrs: { placeholder: "输入搜索用户名、姓名关键字" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.inputVal,
                        callback: function ($$v) {
                          _vm.inputVal = $$v
                        },
                        expression: "inputVal",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { slot: "prepend", placeholder: "请选择" },
                          slot: "prepend",
                          model: {
                            value: _vm.select,
                            callback: function ($$v) {
                              _vm.select = $$v
                            },
                            expression: "select",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "用户名", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "姓名", value: "2" },
                          }),
                        ],
                        1
                      ),
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.search },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mb-10", attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-cascader", {
                        staticClass: "w100",
                        attrs: {
                          clearable: "",
                          placeholder: "请选择部门",
                          props: _vm.cascaderProps,
                        },
                        model: {
                          value: _vm.dtDpid,
                          callback: function ($$v) {
                            _vm.dtDpid = $$v
                          },
                          expression: "dtDpid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: {
                            placeholder: "请选择角色",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.dtPosition,
                            callback: function ($$v) {
                              _vm.dtPosition = $$v
                            },
                            expression: "dtPosition",
                          },
                        },
                        _vm._l(_vm.positionArray, function (item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.staffList,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleStaffChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "用户名", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(_vm._s(scope.row.loginName))]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(_vm._s(scope.row.name))]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  plain: "",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.add(scope.row, scope.$index)
                                  },
                                },
                              },
                              [_vm._v("添 加")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.staffPagination.currentPage,
                  "page-size": _vm.staffPagination.pageSize,
                  layout: "prev, pager, next, jumper",
                  total: _vm.staffPagination.total,
                },
                on: { "current-change": _vm.handleStaffCurrentChange },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-row", [_vm._v("已有成员")]),
              _c(
                "el-row",
                [
                  _c("el-input", {
                    staticStyle: { width: "180px", margin: "10px 10px 10px 0" },
                    attrs: { placeholder: "输入搜索姓名" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchTwo.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inputValTwo,
                      callback: function ($$v) {
                        _vm.inputValTwo = $$v
                      },
                      expression: "inputValTwo",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchTwo },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "tb-button",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.resetTwo },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "用户名", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(_vm._s(scope.row.loginName))]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(_vm._s(scope.row.name))]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  plain: "",
                                  type: "danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.del(scope.row, scope.$index)
                                  },
                                },
                              },
                              [_vm._v("删 除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-size": _vm.pagination.pageSize,
                  layout: "prev, pager, next, jumper",
                  total: _vm.pagination.total,
                },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }