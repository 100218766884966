import $axios from '@/utils/request'

// 获取片区列表
export function getAreaList (data) {
  const url = '/teacharea/list'
  return $axios.fPost(url, data)
}

// 添加片区
export function addTeacharea (data) {
  const url = '/teacharea/add'
  return $axios.fPost(url, data)
}

// 编辑片区
export function editTeacharea (data) {
  const url = '/teacharea/edit'
  return $axios.fPost(url, data)
}

// 删除片区
export function delTeacharea (data) {
  const url = '/teacharea/del'
  return $axios.fGet(url, data)
}

// 获取教区信息
export function getTeachareaInfo (data) {
  const url = '/teacharea/info'
  return $axios.fGet(url, data)
}

// 获取教区成员列表
export function areaListMember (data) {
  const url = '/teacharea/listMember'
  return $axios.fPost(url, data)
}

// 添加教区成员
export function addMember (data) {
  const url = '/teacharea/addMember'
  return $axios.fPost(url, data)
}

// 删除教区成员
export function delMember (data) {
  const url = '/teacharea/delMember'
  return $axios.fPost(url, data)
}
